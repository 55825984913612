// Setting default permissions to true
const defaultPermissions = {
    Home: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Events: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Sessions: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Proctors: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Schedule: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Rooms: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Help: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Notifications: {
        canView: true,
        canCreate: true,
        canDelete: true,
        canEdit: true,
    },
    Profile: { canView: true, canCreate: true, canDelete: true, canEdit: true },
    Flows: { canView: true, canCreate: true, canDelete: true, canEdit: true },
};
const roleSpecificPermissions = {
    PROCTOR: {
        Flows: {
            canView: false,
            canCreate: false,
            canDelete: false,
            canEdit: false,
        },
        Events: {
            canView: false,
            canCreate: false,
            canDelete: false,
            canEdit: false,
        },
        Proctors: {
            canView: false,
            canCreate: false,
            canDelete: false,
            canEdit: false,
        },
        Schedule: {
            canView: false,
            canCreate: false,
            canDelete: false,
            canEdit: false,
        },
    },
};
function getUserMasterPermissions() {
    var _a;
    const storageItem = localStorage.getItem('tv.pc');
    if (!storageItem) {
        return Object.assign({}, defaultPermissions);
    }
    const parsedStorage = JSON.parse(storageItem);
    const roles = (_a = parsedStorage.roles) !== null && _a !== void 0 ? _a : [];
    return evaluateAccessPermissions(roles);
}
function evaluateAccessPermissions(roles) {
    const masterPermissions = Object.assign({}, defaultPermissions);
    roles.forEach((role) => {
        const restrictions = roleSpecificPermissions[role];
        if (restrictions) {
            Object.entries(restrictions).forEach(([key, permission]) => {
                var _a;
                (_a = masterPermissions[key]).canView && (_a.canView = permission.canView);
            });
        }
    });
    return masterPermissions;
}
export { getUserMasterPermissions };
