import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { useLocation, useNavigate } from 'react-router-dom';
import { classNames } from '@utils/classNames';
import { handleEnterKeyPress } from '@utils/helpers';
import SideNavHelp from './SideNavHelp';
import SideNavNotification from './SideNavNotification';
import SideNavProfile from './SideNavProfile';
import SideNavTab from './SideNavTab/SideNavTab';
import Pointer from '../../images/svgs/pointer.svg';
import SidenavLoader from '../../images/svgs/sidenavLoader.svg';
const SideNavigation = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isNewUser, user, logo, navSection, selectedLanguage, handleSetLocale, timeZone, selectedTimezone, handleSetTimezone, } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const handleExpandOnKeyPress = (e) => handleEnterKeyPress(e, () => setIsExpanded(!isExpanded));
    const handleExpandOnClick = () => setIsExpanded(!isExpanded);
    const handleNavigate = (path) => {
        navigate(path);
    };
    return (_jsxs("div", { className: classNames('flex flex-col min-h-screen transition-all duration-500 border-r border-gray-200', isExpanded
            ? 'w-52 shadow-[4px_0px_24px_0px_rgba(17,_24,_39,_0.06)] '
            : 'w-20'), children: [_jsxs("div", { className: "flex flex-col flex-grow px-4 pt-5", children: [_jsxs("div", { className: "flex items-center gap-4", children: [_jsx("div", { role: "button", className: classNames('p-3 cursor-pointer transition-all duration-500 rounded-full w-fit', isExpanded && 'bg-blue-100'), onClick: handleExpandOnClick, onKeyDown: handleExpandOnKeyPress, "data-testid": "toggle_bar", tabIndex: 0, children: _jsx(Bars3Icon, { height: 24, width: 24 }) }), logo && (_jsx("div", { children: _jsx("img", { src: logo, alt: "LOGO", className: "w-full h-[38px] max-w-20 object-contain" }) }))] }), !isNewUser ? (_jsx("div", { className: "flex flex-col gap-3 mt-8 mb-3", children: navSection.map((element) => element.showNavSection && (_jsx(SideNavTab, Object.assign({ 
                            // for storybook testing
                            selected: location.pathname.includes(element.path) }, element, { isExpanded: isExpanded, onClick: () => handleNavigate(element.path) }), element.id))) })) : (_jsxs("div", { className: "flex flex-col items-center h-full mt-[152px] overflow-hidden", children: [_jsxs("div", { className: classNames('flex flex-col items-center mb-[18px] transition-all duration-500  min-w-40 ', isExpanded ? 'opacity-100 ' : 'opacity-0'), "data-testid": "unauthenticated-menu", children: [_jsx("img", { src: Pointer, alt: "Sidenav Pointer", className: "w-16" }), _jsx("span", { className: "mt-3 text-base text-center text-gray-500 ", children: "Choose a project for accessing Flows, Attendees, Sessions, and Reports." })] }), _jsx("img", { className: "w-9", src: SidenavLoader, alt: "Sidenav Loading state" })] }))] }), _jsx("div", { className: "mt-auto px-3.5 pb-11", children: _jsxs("div", { className: classNames('flex flex-col gap-5 border-t border-gray-200 pt-8'), children: [_jsx(SideNavHelp, { isExpanded: isExpanded }), _jsx(SideNavNotification, { isExpanded: isExpanded }), _jsx(SideNavProfile, { timeZone: timeZone, handleSetTimezone: handleSetTimezone, selectedTimezone: selectedTimezone, user: user, isExpanded: isExpanded, selectedLanguage: selectedLanguage, handleSetLocale: handleSetLocale })] }) })] }));
};
export default SideNavigation;
