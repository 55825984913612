import { jsx as _jsx } from "react/jsx-runtime";
import { faClipboardList, faFolder, faCalendarLines, faShapes, } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AcademicCapIcon, CurrencyDollarIcon, HomeIcon, UsersIcon, } from '@heroicons/react/24/outline';
import { getUserMasterPermissions } from '@utils/userPermission';
import Flow from '../../../images/svgs/flow.svg';
const userPermission = getUserMasterPermissions();
export const navSection = [
    {
        id: 'navSection1',
        icon: _jsx(HomeIcon, { className: "w-6 h-6" }),
        label: 'Home',
        path: '/projects',
        showNavSection: typeof userPermission.Home.canView === 'undefined'
            ? true
            : userPermission.Home.canView,
    },
    {
        id: 'navSection2',
        icon: _jsx("img", { src: Flow, alt: "Flow", className: "min-w-6 min-h-6" }),
        label: 'Flows',
        path: '/flows',
        showNavSection: typeof userPermission.Flows.canView === 'undefined'
            ? true
            : userPermission.Flows.canView,
    },
    {
        id: 'navSection4',
        icon: _jsx(FontAwesomeIcon, { icon: faCalendarLines, className: "w-6 h-6" }),
        label: 'Sessions',
        path: '/sessions',
        showNavSection: typeof userPermission.Sessions.canView === 'undefined'
            ? true
            : userPermission.Sessions.canView,
    },
    {
        id: 'navSection3',
        icon: _jsx(UsersIcon, { className: "w-6 h-6" }),
        label: 'Attendees',
        path: '/attendees',
        showNavSection: true,
    },
    {
        id: 'navSection5',
        icon: _jsx(FontAwesomeIcon, { icon: faFolder, className: "w-6 h-6" }),
        label: 'Events',
        path: '/events',
        showNavSection: true,
    },
    {
        id: 'navSection6',
        icon: _jsx(AcademicCapIcon, { className: "w-6 h-6" }),
        label: 'Exams',
        path: '/exams',
        showNavSection: true,
    },
    {
        id: 'navSection7',
        icon: _jsx(CurrencyDollarIcon, { className: "w-6 h-6" }),
        label: 'Payments',
        path: '/payments',
        showNavSection: true,
    },
    {
        id: 'navSection8',
        icon: _jsx(FontAwesomeIcon, { icon: faClipboardList, className: "w-6 h-6" }),
        label: 'Reports',
        path: '/reports',
        showNavSection: true,
    },
    {
        id: 'navSection9',
        icon: _jsx(FontAwesomeIcon, { icon: faShapes, className: "w-6 h-6" }),
        label: 'More Apps',
        path: '/more-apps',
        isArrow: true,
        showNavSection: true,
    },
];
